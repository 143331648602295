import React, { Fragment } from 'react';

export const StateConstitutionalAmendmentLegislative = () => (
  <p>
    According to{' '}
    <a href="https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?lawCode=CONS&division&title&part&chapter&article=XVIII">
      Article XVIII
    </a>{' '}
    of the California Constitution, amendments to the California Constitution initiated by the State
    Legislature must first be approved by a supermajority of both houses and the amendment must then
    be approved by voters with a simple majority of 50% + 1.
  </p>
);

export const StateConstitutionalAmendmentInitiative = () => (
  <p>
    According to{' '}
    <a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CONS&sectionNum=SEC.%208.&article=II">
      Article II, Section 8
    </a>{' '}
    of the California Constitution, amendments to the California Constitution may be introduced by
    collecting signatures (8% of the votes cast in the most recent Governor's race). The amendment
    must then be approved by voters with a simple majority of 50% + 1.
  </p>
);

export const StateInitiative = () => (
  <p>
    According to{' '}
    <a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CONS&sectionNum=SEC.%208.&article=II">
      Article II, Section 8
    </a>{' '}
    of the California Constitution, citizens may introduce statutes (laws) by collecting signatures
    (5% of the votes cast in the most recent Governor's race). The statute must then be approved by
    voters with a simple majority of 50% + 1.
  </p>
);

export const StateInitiativeChange = ({ existingLawLabel = '', existingLawLink = '' }) => {
  let ballotMeasure = 'a ballot measure';
  if (existingLawLabel) {
    ballotMeasure = <a href={existingLawLink}>{existingLawLabel}</a>;
  }
  return (
    <Fragment>
      <p>
        According to{' '}
        <a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CONS&sectionNum=SEC.%208.&article=II">
          Article II, Section 8
        </a>{' '}
        of the California Constitution, citizens may introduce statutes (laws) by collecting
        signatures (5% of the votes cast in the most recent Governor's race). The statute must then
        be approved by voters with a simple majority of 50% + 1.
      </p>
      <p>
        Since the law being changed was originally passed via {ballotMeasure}, the state legislature
        does not have the power to change it themselves. By law, any changes to the law must be
        approved by the voters.
      </p>
    </Fragment>
  );
};

export const CityCharterAmendmentLegislative = () => (
  <p>
    This is a City Charter amendment introduced by the Board of Supervisors. Since the Board of
    Supervisors cannot directly amend the Charter, if they want to change the Charter they must go
    to the voters. A vote of six or more Supervisors is required to place a Charter Amendment on the
    ballot, and voters must approve it by a 50% + 1 majority.
  </p>
);

export const CityCharterAmendmentInitiative = () => (
  <p>
    Citizens may introduce charter amendments by collecting signatures from at least 10% of the
    registered voters in the City. The amendment must then be approved by voters with a simple
    majority of 50% + 1.
  </p>
);

export const CityMayorInitiative = () => (
  <p>
    This was placed on the ballot by the Mayor. Since the Mayor cannot write laws, if the Mayor
    wants a law changed they must go directly to the voters. It needs simple majority of 50% + 1 to
    pass.
  </p>
);

export const CitySupervisorInitiativeMinority = () => (
  <p>
    This was placed on the ballot by four or more members of the Board of Supervisors, but fewer
    than a simple majority of six. Since the law didn't have majority support, the Supervisors in
    the minority are taking it directly to the voters. It needs simple majority of 50% + 1 to pass.
  </p>
);

export const CitySupervisorInitiativeMajority = () => (
  <p>
    This was placed on the ballot by six or more members of the Board of Supervisors. It could have
    been passed by the Board of Supervisors, but they decided to take it directly to the voters.
    Typically, this is done by Supervisors who are facing re-election and want to use the ballot
    measure to boost their name recognition, and often to bypass campaign contribution limits for
    their own campaigns. It needs simple majority of 50% + 1 to pass.
  </p>
);

export const CitySignatureInitiative = () => (
  <p>
    This was placed on the ballot by collecting signatures from voters. It needs simple majority of
    50% + 1 to pass.
  </p>
);

export const CitySchoolBond = () => (
  <p>
    California Education Code{' '}
    <a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=EDC&sectionNum=15266.">
      section 15266
    </a>{' '}
    and{' '}
    <a href="https://ballotpedia.org/Article_XVI,_California_Constitution#Section_18">
      Section 18 of Article XVI
    </a>{' '}
    of the California Constitution mandate that School District bonds be put to a vote. Bonds must
    be approved by the voters by a 55% + 1 majority.
  </p>
);

export const CityBond = () => (
  <p>
    California Government Code{' '}
    <a href="http://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=GOV&sectionNum=53506">
      section 53506
    </a>{' '}
    and{' '}
    <a href="https://ballotpedia.org/Article_XVI,_California_Constitution#Section_1">Article XVI</a>{' '}
    of the California Constitution mandate that general obligation bonds of this size be put to a
    vote. Bonds must be approved by the voters by a 66.66% + 1 majority.
  </p>
);

export const StateBond = () => (
  <p>
    <a href="https://ballotpedia.org/Article_XVI,_California_Constitution#Section_1">Article XVI</a>{' '}
    of the California Constitution mandates that debt exceeding $300,000 (including state bonds) be
    submitted to the public for a vote. Bonds must first be approved by a two-thirds majority of
    both houses of the state Legislature, and then by a simple majority of the voters.
  </p>
);
